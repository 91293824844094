@use 'sass:list';
@import '../colors';

@mixin get-text-color($background) {
  @if list.index($dark-colors, $background) {
    color: $color-white;
  } @else {
    color: $color-black;
  }
}
