@import '../../../../packages/photon/theme/global';
@import '../../atoms/text-cta/text-cta.module';

.teaser {
  display: grid;
  position: relative;
  width: 100%;
  transition: transform 0.1s;
  border-radius: $border-radius-s;

  @include focus-ring;

  &:active {
    transform: scale(0.98);
    outline: none;
  }

  .text:not(:last-child) {
    margin-bottom: $spacing-xs;
  }

  .headline {
    margin-bottom: $spacing-xxs;

    @media (min-width: $mq-medium-and-above) {
      margin-bottom: $spacing-xs;
    }
  }

  .chip {
    margin-bottom: $spacing-xs;
  }

  .text-cta {
    margin-top: auto;

    @include text-cta;

    .text-cta-text {
      display: inline;

      @include text-cta-text;
    }
  }

  @each $color-name, $color-value in $colors {
    &-bg-#{'' + $color-name} {
      @include get-text-color($color-value);

      @media (hover: hover) {
        &:hover,
        &:active {
          .container {
            background: color-mix(in srgb, currentcolor 11%, $color-value);
          }

          .media::after {
            opacity: 1;
          }

          .text-cta-text {
            @include link-underline-hover;
          }
        }
      }
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;
  width: 100%;
  margin-top: 0;
  padding: $spacing-s;

  &-text-outside {
    margin-top: $spacing-s;
    padding: 0;
  }
}

.media {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 0;

  img {
    width: 100%;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.1s;
    opacity: 0;
    background: color-mix(in srgb, currentcolor 11%, transparent);
  }
}

.container {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  transition: background-color 0.1s;
  border-radius: $border-radius-s;

  @each $color-name, $color-value in $colors {
    &-bg-#{'' + $color-name} {
      @include get-text-color($color-value);

      background-color: $color-value;
    }
  }
}
