@import '../../../photon/theme/global';
@import '../../../photon/molecules/textfield/textfield.shared.module';

.calculator-input-row {
  display: flex;
  gap: $spacing-xxs;
  align-items: center;
  margin-top: $spacing-xxs;

  @media (min-width: $mq-small-and-above) {
    margin-top: $spacing-xs;
  }
}

.calculator-usage-input {
  @include shared-input-base;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
  }

  min-width: 6ch !important;
  max-width: 8ch !important;
  height: 1.25rem;
  /* stylelint-disable-next-line meowtec/no-px */
  margin: 0 4px;
  padding: 0;
  /* stylelint-disable-next-line meowtec/no-px */
  border-radius: 4px;
  background: $color-faded-gray;
  font-size: 0.75rem;
  font-weight: bold;
  appearance: none;
  text-align: center;

  &:focus-visible {
    border: $border-width-input solid $state-color-focus;
  }

  &-error {
    border: $border-width-input solid $state-color-error;
  }
}

.modal-content {
  display: grid;
  gap: $spacing-xs;
}
