@import '../../theme/colors';
@import '../../theme/opacities';
@import '../../theme/spacing';

label.label {
  display: flex;
  align-items: flex-start;
  gap: $spacing-xxs;
  color: $color-black;
  cursor: pointer;

  &-disabled {
    color: rgba($color-black, $opacity-disabled);
  }

  & > span {
    text-align: left;
  }
}
