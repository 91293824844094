@import '../../../../photon/theme/global';

.container {
  position: relative;
}

.loader-container {
  display: flex;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: $color-background-gray;
}
