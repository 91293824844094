@use 'sass:math';
@import '../../../photon/theme/global';

.container {
  padding-top: $spacing-l;
  padding-bottom: $spacing-l;
  scroll-margin-top: $spacing-3xl;

  @include content-container;

  @media (min-width: $mq-small-and-above) {
    padding-top: $spacing-xl;
    padding-bottom: $spacing-xl;
  }

  @each $color-name, $color-value in $cms-colors {
    &-bg-#{'' + $color-name} {
      @include background-text-color($color-value);
    }
  }
}

.text-intro {
  margin-bottom: $spacing-m;

  @media (min-width: $mq-medium-and-above) {
    width: math.div(100%, 12) * 8;
    margin: 0 auto;
    margin-bottom: $spacing-l;
  }
}

.column-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.wrapper {
  @each $color-name, $color-value in $cms-colors {
    &-full-boxed#{&}-bg-#{'' + $color-name} {
      @include background-text-color($color-value);
    }

    &-bottom-boxed#{&}-bg-#{'' + $color-name} {
      .column-items {
        @include background-text-color($color-value);
      }
    }
  }

  &-full-boxed {
    padding: $spacing-xs;
    border-radius: $border-radius-s;

    @media (min-width: $mq-medium-and-above) {
      padding: $spacing-m $spacing-s;
    }
  }

  &-bottom-boxed {
    .column-items {
      padding: $spacing-xs;
      border-radius: $border-radius-s;

      @media (min-width: $mq-medium-and-above) {
        padding: $spacing-m $spacing-s;
      }
    }
  }
}
