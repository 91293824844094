@import '../breakpoints';

@mixin content-container {
  padding-right: $spacing-xs;
  padding-left: $spacing-xs;
  scroll-margin-top: 8rem;

  @media (min-width: $mq-medium-and-above) {
    padding-right: max(3.5rem, calc((100% - #{$breakpoint-large}px) / 2 + 3.5rem));
    padding-left: max(3.5rem, calc((100% - #{$breakpoint-large}px) / 2 + 3.5rem));
  }
}
