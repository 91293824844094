@import '../../../photon/theme/global';

.section {
  display: grid;
  gap: $spacing-xs;

  @include content-container;
  @include padding-classes;

  @media (min-width: $mq-small-and-above) {
    &-columns-1 {
      grid-template-columns: 1fr;
      padding-right: calc(
        max(3.5rem, calc((100% - #{$breakpoint-large}px) / 2 + 3.5rem)) + min(100%, #{$breakpoint-large}px) / 6
      );
      padding-left: calc(
        max(3.5rem, calc((100% - #{$breakpoint-large}px) / 2 + 3.5rem)) + min(100%, #{$breakpoint-large}px) / 6
      );
    }

    &-columns-2 {
      grid-template-columns: 1fr 1fr;

      .text-intro,
      .button {
        grid-column: span 2;
      }
    }
  }

  &-bg-faded-gray {
    @include background-text-color($color-faded-gray);
  }

  &-bg-white {
    @include background-text-color($color-white);
  }
}

.text-intro {
  margin-bottom: $spacing-xs;

  @media (min-width: $mq-small-and-above) {
    margin-bottom: $spacing-m;
  }
}

.button {
  margin-top: $spacing-xs;

  @media (min-width: $mq-small-and-above) {
    margin-top: $spacing-m;
  }
}
