@import '../../theme/spacing';
@import '../../theme/font-weight';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-xxs;
  border: none;
}

.legend {
  margin-bottom: 0.75rem;
  font-weight: $font-weight-bold;
}
