@import '../../../photon/theme/global';

.accordion-item {
  position: relative;
  margin-top: $spacing-xxs;
  border-radius: $border-radius-xs;
  background: $color-white;
}

.accordion-content {
  padding: $spacing-xs;
}

.radio-button {
  margin: 0;
}

.radio-input {
  padding: $spacing-xxs;
  border: solid 2px $color-black;
  border-radius: $spacing-xxs;
  cursor: pointer;
}

.calculator-content {
  display: grid;
  gap: $spacing-xs;
  width: 100%;
  padding: $spacing-xs $spacing-xs $spacing-s;

  @media (min-width: $mq-small-and-above) {
    padding: $spacing-xs $spacing-s $spacing-s;
    gap: $spacing-s;
  }
}

.calculator-input-row {
  display: grid;
  gap: $spacing-xs;

  button {
    line-height: 0;
  }

  @media (min-width: $mq-small-and-above) {
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
  }
}

.label {
  display: flex;
  align-items: center;
  gap: $spacing-xxs;
  cursor: pointer;
}

.modal-content {
  display: grid;
  gap: $spacing-xs;
}

.modal-box-cta {
  @media (max-width: $mq-small-and-above) {
    width: 100%;
  }
}
