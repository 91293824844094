@import '../../../photon/theme/global';

.content {
  display: grid;
  width: 100%;
  padding: $spacing-xs $spacing-s $spacing-s;
  border-radius: 0 0 0.375rem 0.375rem;
  background: $color-orange;
  gap: $spacing-s;
}
