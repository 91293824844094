@use 'sass:math';
@import '../../../photon/theme/global';

.container {
  padding-top: $spacing-m;
  padding-bottom: $spacing-m;

  @include content-container;

  @media (min-width: $mq-medium-and-above) {
    display: grid;
    grid-template-columns: 6fr 6fr;

    &:not(.full-width) > *:last-child {
      margin-left: math.div(100%, 12) * 2;
    }
  }

  &.full-width {
    display: block;

    @media (min-width: $mq-medium-and-above) {
      > * {
        margin-right: math.div(100%, 12) * 1;
        margin-left: math.div(100%, 12) * 1;
      }
    }
  }

  &-bg-faded-gray {
    @include background-text-color($color-faded-gray);
  }

  &-bg-white {
    @include background-text-color($color-white);
  }
}

.text-intro {
  margin-bottom: $spacing-m;

  @media (min-width: $mq-medium-and-above) {
    margin-bottom: 0;

    .full-width & {
      margin-bottom: $spacing-l;
    }
  }
}
