@use 'sass:math';
@import '../../../photon/theme/global';

.section {
  display: grid;
  background: $color-faded-gray;

  @include content-container;
  @include padding-classes;

  @media (min-width: $mq-medium-and-above) {
    & > * {
      width: math.div(100%, 12) * 8;
      margin: 0 auto;
    }
  }
}
