@import '../spacing';
@import '../mediaqueries';

@mixin padding-classes {
  @each $padding-name, $padding-prop in ('pbm': 'padding-bottom', 'ptm': 'padding-top') {
    @each $spacing-name, $spacing-value in $spacings {
      &-#{'' + $padding-name}-#{'' + $spacing-name} {
        #{'' + $padding-prop}: $spacing-value;
      }
    }

    &-#{'' + $padding-name}-none {
      #{'' + $padding-prop}: 0;
    }
  }

  @media (min-width: $mq-medium-and-above) {
    @each $padding-name, $padding-prop in ('pbd': 'padding-bottom', 'ptd': 'padding-top') {
      @each $spacing-name, $spacing-value in $spacings {
        &-#{'' + $padding-name}-#{'' + $spacing-name} {
          #{'' + $padding-prop}: $spacing-value;
        }
      }

      &-#{'' + $padding-name}-none {
        #{'' + $padding-prop}: 0;
      }
    }
  }
}
