@import '../filter-select/filter-select.shared.module';
@import '../textfield/textfield.shared.module';

.wrapper {
  @include shared-wrapper;

  position: relative;
}

.label-wrapper {
  @include shared-label-wrapper;
}

.input-row {
  position: relative;

  @include shared-input-row-base;

  &-success {
    @include shared-input-row-success;
  }

  &-error {
    @include shared-input-row-error;
  }

  &-disabled {
    @include shared-input-row-disabled;
  }

  &[data-open]:focus-within {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.slot {
  @include shared-slot;
}

.input {
  @include shared-input-right-slot;
  @include shared-input-base;
}

.icon-wrapper {
  @include shared-icon-wrapper;
}
