@import './../../theme/animations';
@import './../../theme/border';
@import './../../theme/colors';
@import './../../theme/mixins/focus-ring';
@import './../../theme/shadows';
@import './../../theme/spacing';

%root {
  position: relative;

  & > .variant-base-container,
  & > .variant-input-container {
    grid-template-rows: 0fr;
    opacity: 0;
    pointer-events: none;
  }

  &[data-open]:focus-within > .variant-base-container,
  &[data-open]:focus-within > .variant-input-container {
    grid-template-rows: 1fr;
    opacity: 1;
    pointer-events: all;
  }
}

%container {
  display: grid;
  position: absolute;
  z-index: 100;
  top: 100%;
  right: calc($border-width-input * -1);
  left: calc($border-width-input * -1);
  grid-template-columns: 100%;
  margin-top: calc(2 * $border-width-input);
  overflow: hidden; // for Safari < 16
  overflow: clip;
  transition: all $large-outro;
  border-radius: $border-radius-s;
  background: $color-white;
  box-shadow: $shadow-medium;
}

%content {
  min-height: 0;
  color: $color-black;
}

%options {
  max-height: 25rem;
  padding: $spacing-xxs 0;
  overflow-y: auto;
  list-style: none;
  mask-image: linear-gradient(0deg, rgb(0 0 0 / 0%) 0, rgb(0 0 0 / 100%) 8%, rgb(0 0 0 / 100%) 92%, rgb(0 0 0 / 0%));
}

%option {
  display: block;
  margin: 0 $spacing-xs;
  padding: $spacing-3xs $spacing-xxs;
  border-radius: $border-radius-s;
  cursor: pointer;

  &:not(:first-child) {
    position: relative;
    margin-top: calc(2 * $spacing-3xs + 1px);

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: calc($spacing-3xs * -1 - 1px);
      right: 0;
      left: 0;
      border-top: 1px solid $color-light-gray;
    }
  }

  &:hover {
    background-color: $color-faded-gray;
  }

  &:focus {
    outline-offset: calc($spacing-3xs * -1);
    color: $color-black;

    @include focus-ring;
  }
}

%no-result {
  margin: 0 $spacing-xxs !important;
  padding: $spacing-3xs $spacing-xxs;
  color: $color-dark-gray;
}

.variant-base {
  &-container {
    @extend %container;
  }

  &-root {
    @extend %root;
  }

  &-content {
    @extend %content;
  }

  &-options {
    @extend %options;
  }

  &-option {
    @extend %option;
  }

  &-no-result {
    @extend %no-result;
  }
}

.variant-input {
  &-container {
    @extend %container;

    margin-top: 0;
    border: inherit;
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: none;
  }

  &-root {
    @extend %root;
  }

  &-content {
    &::before {
      content: '';
      display: block;
      margin: 0 $spacing-xs;
      border: none;
      border-bottom: $border-width-input solid $color-light-gray;
    }

    @extend %content;
  }

  &-options {
    mask-image: linear-gradient(0deg, rgb(0 0 0 / 0%) 0, rgb(0 0 0 / 100%) 15%, rgb(0 0 0 / 100%) 85%, rgb(0 0 0 / 0%));
    max-height: 13.5rem;

    @extend %options;
  }

  &-option {
    margin: 0 $spacing-xxs !important;

    &::before {
      content: none !important;
    }

    @extend %option;
  }

  &-no-result {
    @extend %no-result;
  }
}
